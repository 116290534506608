import {
  Box,
  Image,
  Flex,
  VStack,
  useToast,
  Text,
  Button,
  Input,
  FormErrorMessage,
  useColorModeValue,
  FormControl,
  HStack,
  Stack,
  PinInputField,
  PinInput,
} from "@chakra-ui/react";
import axios from "axios";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import HelloSign from "hellosign-embedded";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import rolesMap from "./roles-mapping.json";

const marketValuationFormSchedma = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  name: Yup.string().required("Name is required"),
});

const client = new HelloSign();

function App() {
  const toast = useToast({
    duration: 3000,
    position: "top-right",
    isClosable: true,
  });
  const [searchParams] = useSearchParams();
  const [formId, setFormId] = useState("");
  const [formData, setFormData] = useState(undefined);

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);

  const token = searchParams.get("token");

  useEffect(() => {
    const formId = searchParams.get("tid");
    if (formId) {
      setFormId(formId);
    }
  }, [searchParams, toast]);

  const submitForm = (values, actions) => {
    if (formId) {
      actions.setSubmitting(true);
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/market-valuation/generate-otp`,
          {
            email: values.email,
          }
        )
        .then((res) => {
          setFormSubmitted(true);
          setFormData(values);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          actions.setSubmitting(false);
        });
    }
  };

  const verifyOtp = (otp) => {
    if (!otp) {
      return;
    }
    setOtpLoading(true);

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/market-valuation/verify-email`, {
        email: formData.email,
        otp,
      })
      .then((res) => {
        const { data } = res;
        if (data.verified) loadEsign();
        else {
          setOtpLoading(false);
          toast({
            title: "Error!",
            status: "error",
            description: data.message,
          });
        }
      })
      .catch((err) => {
        setOtpLoading(false);

        console.log(err);
      });
  };

  const loadEsign = () => {
    const formID = formId.split(",").length > 0 ? formId.split(",")[0] : formId;
    const role = rolesMap.find((x) => x.templateID === formID);

    axios
      .post(
        `${process.env.REACT_APP_SIGNING_BASE_URL}/signature_request/create_embedded_with_template`,
        {
          client_id: process.env.REACT_APP_HELLOSIGN_CLIENT_ID,
          template_ids: [...formId.split(",")],
          signers: [
            {
              role: "Client",
              name: formData.name,
              email_address: formData.email,
            },
          ],
          ccs: role
            ? [
              {
                role: role.role,
                email_address: role.email,
              },
              {
                role: "Client",
                email_address: formData.email,
              },
            ]
            : [],
          signing_options: {
            draw: true,
            type: true,
            default_type: "draw",
          },
          title: role ? `${formData.name} ${role.name}` : "",
          test_mode: "false",
          skipDomainVerification: true,
        },
        {
          headers: {
            Authorization: `Basic ${process.env.REACT_APP_HELLOSIGN_CLIENT_TOKEN}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        axios
          .get(
            `${process.env.REACT_APP_SIGNING_BASE_URL}/embedded/sign_url/` +
            response.data.signature_request.signatures[0].signature_id,
            {
              headers: {
                Authorization: `Basic ${process.env.REACT_APP_HELLOSIGN_CLIENT_TOKEN}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((response2) => {
            client.open(response2.data.embedded.sign_url, {
              clientId: process.env.REACT_APP_HELLOSIGN_CLIENT_ID,
              skipDomainVerification: true,
            });
          })
          .catch((error) => {
            toast({
              status: "error",
              title: "Abort!",
              description: "Something went wrong. Please reload the page.",
            });
            console.error(error);
          });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  client.on("sign", async () => {
    setFormSubmitted(false);

    toast({
      status: "success",
      title: "Form signed successfully",
    });
    setTimeout(() => {
      window.close();
    }, 5000);
  });

  client.on("cancel", async () => {
    setFormSubmitted(false);
    toast({
      status: "error",
      title: "You cancelled form signing",
    });
    setTimeout(() => {
      window.close();
    }, 5000);
  });

  const isFormVisible = !token && !formSubmitted;

  return (
    <Flex
      minH={"100vh"}
      align={"center"}
      justify={"center"}
      bg={useColorModeValue("gray.50", "gray.800")}
      direction={{ base: "column", lg: "row" }}>
      {isFormVisible && (
        <Formik
          initialValues={{
            name: "",
            email: "",
          }}
          validationSchema={marketValuationFormSchedma}
          onSubmit={submitForm}>
          {({ isSubmitting, errors, touched, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <VStack minW="50vw" spacing="4">
                <Image
                  width="300px"
                  src="https://directedira.com/wp-content/uploads/2021/02/directedIRA_logo.svg"
                />
                <Box w="100%">
                  <Text>Name</Text>
                  <FormControl isInvalid={!!errors.name && touched.name}>
                    <Field
                      w="100%"
                      as={Input}
                      placeholder="Your name here..."
                      _placeholder={{ color: "gray.500" }}
                      id="name"
                      name="name"
                      variant="filled"
                    />
                    <FormErrorMessage>{errors.name}</FormErrorMessage>
                  </FormControl>
                </Box>
                <Box w="100%">
                  <Text>Email</Text>
                  <FormControl isInvalid={!!errors.email && touched.email}>
                    <Field
                      w="100%"
                      as={Input}
                      placeholder="Your email here..."
                      _placeholder={{ color: "gray.500" }}
                      id="email"
                      name="email"
                      type="email"
                      variant="filled"
                    />
                    <FormErrorMessage>{errors.email}</FormErrorMessage>
                  </FormControl>
                </Box>
                <Button
                  w="100%"
                  bg={"brand.900"}
                  color={"white"}
                  _hover={{
                    bg: "brand.700",
                  }}
                  isDisabled={
                    isSubmitting ||
                    formId === "" ||
                    formId === undefined ||
                    formId === null
                  }
                  isLoading={isSubmitting}
                  type="submit">
                  Send E-Sign Request
                </Button>
              </VStack>
            </form>
          )}
        </Formik>
      )}

      {!isFormVisible && (
        <Stack direction={{ base: "column" }} align="center" justify="center">
          <p>We have sent a verification code to your email</p>
          <p style={{ fontWeight: "bold" }}>{formData?.email}</p>
          <HStack>
            <PinInput
              size="lg"
              type="number"
              onComplete={verifyOtp}
              isDisabled={otpLoading}>
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
            </PinInput>
          </HStack>
        </Stack>
      )}
    </Flex>
  );
}

export default App;
