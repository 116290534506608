import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import { BrowserRouter } from 'react-router-dom';

const theme = extendTheme({
  components: {
    Button: { baseStyle: { _focus: { boxShadow: "none" } } },
  },
  colors: {
    brand: {
      100: "rgba(236, 68, 40, 0.2)",
      200: "rgba(236, 68, 40, 0.3)",
      300: "rgba(236, 68, 40, 0.4)",
      400: "rgba(236, 68, 40, 0.5)",
      500: "rgba(236, 68, 40, 0.6)",
      600: "rgba(236, 68, 40, 0.7)",
      700: "rgba(236, 68, 40, 0.8)",
      800: "rgba(236, 68, 40, 0.9)",
      900: "rgba(236, 68, 40, 1)",
    },
    greyprogress: {
      500: "#DEDEDE",
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ChakraProvider theme={theme}>
        <App />
      </ChakraProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
